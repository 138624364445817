import React, { Component } from 'react';

export const WindowSizeContext = React.createContext(false);

export class WindowSizeProvider extends Component {
  state = {
    isMobile: false
  };

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ isMobile: window.innerWidth < 850 });
  };

  render() {
    const { children } = this.props;

    return (
      <WindowSizeContext.Provider value={this.state.isMobile}>
        {children}
      </WindowSizeContext.Provider>
    );
  }
}
