import React, { Component, createRef } from 'react';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';
import { isFunction } from 'util';
import { ModuleVideoGallery } from './module-video-gallery';
import { Transition } from 'react-spring/renderprops';
import './lightbox.scss';
// import { graphql, StaticQuery } from 'gatsby';
import { PanZoom } from 'react-easy-panzoom';
import { ZoomIncrease } from './icons/zoom-increase';
import { ZoomDecrease } from './icons/zoom-decrease';

export class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.panZoom = createRef();

    this.state = {
      showLightbox: false
    };
  }

  open = () => {
    this.setState({ showLightbox: true });
  };

  close = event => {
    if (
      event.target.getAttribute('type') !== 'button' &&
      !event.target.src &&
      !event.target.getAttribute('viewBox') &&
      this.props.closable !== undefined &&
      this.props.closable !== false
    ) {
      this.setState({ showLightbox: false });
    }
  };

  render() {
    const { showLightbox } = this.state;

    return (
      <>
        {isFunction(this.props.children) && this.props.children({ openLightbox: this.open })}
        <Transition
          items={showLightbox}
          from={{ opacity: 0, y: -10 }}
          enter={{ opacity: 1, y: 0 }}
          leave={{ opacity: 0, y: 10 }}
        >
          {show =>
            show &&
            (props => (
              <Dialog
                style={{
                  position: 'relative',
                  padding: '60px',
                  opacity: props.opacity,
                  overflow: 'hidden'
                }}
                className="lightbox"
                onClick={this.close}
              >
                {this.props.usePanZoom ? (
                  <>
                    <PanZoom
                      autoCenter={true}
                      minZoom={1}
                      ref={this.panZoom}
                      autoCenterZoomLevel={1}
                    >
                      {this.props.content}
                    </PanZoom>
                    <div
                      className="zoomControls"
                      style={{ position: 'absolute', top: 70, right: 10, zIndex: 100 }}
                      onClick={event => event.stopPropagation()}
                    >
                      <span onClick={() => this.panZoom.current.zoomIn()}>
                        <ZoomIncrease width="35" height="35" />
                      </span>
                      <span onClick={() => this.panZoom.current.zoomOut()}>
                        <ZoomDecrease width="35" height="35" />
                      </span>
                    </div>
                  </>
                ) : (
                  this.props.content
                )}
                {this.props.closable === undefined || this.props.closable === true ? (
                  <button
                    type="button"
                    onClick={() => this.setState({ showLightbox: false })}
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 10,
                      border: 0,
                      background: 'transparent',
                      color: 'white',
                      cursor: 'pointer',
                      fontSize: 40,
                      outline: 'none',
                      height: 45
                    }}
                  >
                    ✕
                  </button>
                ) : (
                  ''
                )}
              </Dialog>
            ))
          }
        </Transition>
      </>
    );
  }
}

export const withLightbox = Component => {
  return (
    <Lightbox content={<Component />}>
      {({ openLightbox }) => (
        <div onClick={openLightbox} style={{ cursor: 'pointer' }}>
          <Component />
        </div>
      )}
    </Lightbox>
  );
};

export const withLightBoxGallery = (Component, items) => {
  const gallery = <ModuleVideoGallery items={items} />;
  return (
    <Lightbox content={gallery}>
      {({ openLightbox }) => (
        <div onClick={openLightbox} style={{ cursor: 'pointer' }}>
          <Component />
        </div>
      )}
    </Lightbox>
  );
};
