import React from 'react';

export const ZoomDecrease = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.14 163.57" {...props}>
      <path d="M123.67 61.14c0-34.1-27.74-61.83-61.83-61.83S0 27.05 0 61.14s27.74 61.83 61.83 61.83 61.84-27.73 61.84-61.83zm-61.84 49.84C34.36 110.98 12 88.62 12 61.14s22.36-49.83 49.83-49.83 49.83 22.35 49.83 49.83-22.35 49.84-49.83 49.84z" />
      <path d="M95.289324 119.236388l11.50322-9.167272 35.33544 44.3394-11.50322 9.167272zM33.6634 65.486v-8.03h55.67v8.03z" />
    </svg>
  );
};
