import { API_ENDPOINT } from '.';

class FeedbackError extends Error {}

/**
 * Sends user feedback to the API
 * @param {{subject: string, text: string, email: string, name: string, captchaToken: string}} param0
 * @return {Promise<[any, FeedbackError]>}
 */
export const sendFeedback = ({ subject, text, email, name, captchaToken }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/feedback`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        subject,
        text,
        email,
        name,
        captchaToken: captchaToken
      })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return resolve([true, undefined]);
          case 406:
            return resolve([
              undefined,
              new FeedbackError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          default:
            return resolve([undefined, new FeedbackError('Es ist etwas schief gelaufen.')]);
        }
      })
      .catch(e => {
        console.error(e);
        return resolve([
          undefined,
          new FeedbackError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};
