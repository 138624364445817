export const API_ENDPOINT = process.env.GATSBY_API_URL;
export {
  uploadImage,
  createPost,
  getPosts,
  likePost,
  blamePost,
  commentPost,
  getCommentsPost,
  likeComment,
  blameComment
} from './post';
export { sendFeedback } from './feedback';
export { getUser, patchUser, login } from './user';
