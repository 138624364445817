import React from 'react';
import { getUser } from '../api/user';
import { API_ENDPOINT } from '../api';

const defaultState = {
  loading: true,
  loggedIn: false,
  error: null,
  // pure userdata
  username: null,
  department: null,
  avatar: null,
  roles: [],
  email: null,
  loginOpen: false,
  openLogin: params => {},
  closeLogin: () => {},
  action: 'default',
  callback: undefined
};
defaultState.setUser = () => {};

export const UserContext = React.createContext(defaultState);

export class UserProvider extends React.Component {
  state = defaultState;

  componentDidMount() {
    getUser()
      .then(([user, err]) => {
        if (err) {
          return this.setState({ loggedIn: false, error: err.message, loading: false });
        }
        this.setState({ loggedIn: true, error: null, loading: false, ...user });
      })
      .catch(e => {
        console.error(e);
        this.setState({ loggedIn: false, error: 'Something unexpected happened!', loading: false });
      });
  }

  login = user => {
    this.setState({ loggedIn: true, error: null, loading: false, ...user });
    if (this.state.callback) {
      this.state.callback();
      this.setState({ callback: undefined });
    }
  };

  logout = () => {
    const { setUser, ...rest } = defaultState;
    this.setState({ loading: false, loggedIn: false, rest });
    return fetch(`${API_ENDPOINT}/logout`, {
      credentials: 'include',
      method: 'GET'
    }).then(() => console.log('logged out'));
  };
  openLogin = params => {
    this.setState({
      loginOpen: true,
      action: params && params.action ? params.action : 'default',
      params: params
    });
  };
  closeLogin = () => {
    this.setState({ loginOpen: false });
    // console.log('closeLogin');
  };
  afterLogin = callback => {
    if (callback) {
      this.setState({ callback });
    }
  };

  render() {
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          ...this.state,
          login: this.login,
          logout: this.logout,
          openLogin: this.openLogin,
          closeLogin: this.closeLogin,
          afterLogin: this.afterLogin
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}
