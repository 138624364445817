import React from 'react';

export const ZoomIncrease = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142.14 164.26" {...props}>
      <path d="M123.67 61.83C123.67 27.74 95.93 0 61.83 0S0 27.74 0 61.83s27.74 61.83 61.83 61.83 61.84-27.73 61.84-61.83zm-61.84 49.84C34.36 111.67 12 89.31 12 61.83S34.36 12 61.83 12s49.83 22.35 49.83 49.83-22.35 49.84-49.83 49.84z" />
      <path d="M95.289032 119.926568l11.50322-9.167272 35.33544 44.3394-11.50322 9.167272zM58.26 34.33h8.03V90h-8.03z" />
      <path d="M33.6628 66.1765v-8.03h55.67v8.03z" />
    </svg>
  );
};
